<template>
  <div id="taskInfo">
    <BackApprove title="审批详情"></BackApprove>
    <!-- 审批头部 -->
    <section class="box">
      <h1 class="title van-hairline--bottom">基础信息</h1>
      <div class="middle">
        <template v-if="taskHead && taskHead.approveFormValueDtoList">
          <div
            class="mid-item"
            v-for="item in taskHead.approveFormValueDtoList"
            :key="item.formItemName"
          >
            <div class="item-title">{{ item.formItemName }}:</div>
            <div class="item-text" v-if="item.formItemValue">
              <p class="item-text-p" v-for="i in item.formItemValue">
                <span v-html="i"></span>
              </p>
            </div>
            <div class="item-text" v-else>暂无</div>
          </div>
        </template>
        <van-empty v-else description="暂无数据" />
      </div>
    </section>
    <!-- 审批参考 -->
    <Reference
      title="审批参考"
      v-if="processType == 1 || processType == 80"
      :clueId="clueId"
      :sellPriceList="sellPriceList"
      :sameRoomList="sameRoomList"
    ></Reference>
    <!-- 电子合同 -->
    <ContractSign
      title="电子合同"
      v-if="processType == 1 || processType == 3 || processType == 80"
      :taskHead="taskHead"
      :processType="processType"
      :contractFileFlag="contractFileFlag"
      :wuyeFileFlag="wuyeFileFlag"
      :contractFileImg="contractFileImg"
    ></ContractSign>
    <!-- 附件信息 -->
    <AccessoryFile
      title="附件信息"
      v-if="approveFileList.length > 0"
      :approveFileList="approveFileList"
    ></AccessoryFile>
    <!-- 关联工单 -->
    <FormWorkInfo
      title="关联工单"
      v-if="formWorkInfo"
      :formWorkInfo="formWorkInfo"
    ></FormWorkInfo>
    <!-- 审批评论 -->
    <section class="box">
      <van-image
        v-if="taskList.approveStatus"
        class="approve-png"
        width=".6rem"
        height=".6rem"
        :src="approveStatusPngObj[taskList.approveStatus]"
      />
      <h1 class="title van-hairline--bottom">审批评论</h1>
      <div class="middle">
        <ul class="task-list" v-if="taskCommentList.length">
          <li
            class="list-item"
            v-for="(item, index) in taskCommentList"
            :key="index"
          >
            <div class="list-img">
              <van-image
                round
                width=".32rem"
                height=".32rem"
                :src="changeHeadImg(item)"
              />
              <div :class="{ line: index != taskCommentList.length - 1 }"></div>
            </div>
            <div
              class="list-status van-hairline--bottom"
              v-if="item.executionId"
            >
              <p>
                <span>
                  {{ item.nodeName }}
                  <span
                    v-html="
                      changeComment(
                        item.executionStatus,
                        item.executionAction,
                        item.nodeType
                      )
                    "
                  ></span>
                </span>
                <span
                  class="font-12"
                  v-if="item.nodeType == 3 && item.executionTime"
                  >{{ formatDate(item.executionStartTime, 5) }} 至
                  {{ formatDate(item.executionTime, 5) }}</span
                >
                <span class="font-12" v-else>{{
                  formatDate(item.executionTime, 5)
                }}</span>
              </p>
              <p
                v-if="
                  item.assigneeType == 3 &&
                  item.executionStatus != 2 &&
                  item.executionStatus != 3
                "
              >
                {{ item.roleName }}
              </p>
              <p v-else>
                {{ item.operatorName }}
                <template v-if="item.assigneeEventActionName"
                  >({{ item.assigneeEventActionName }})</template
                >
              </p>
              <template
                v-if="
                  item.dealActionVo &&
                  item.dealActionVo.params &&
                  item.dealActionVo.params.length > 0
                "
              >
                <p
                  style="font-size: 0.12rem; color: #999"
                  v-for="it in item.dealActionVo.params"
                >
                  <b>{{ it.key }}：</b>
                  <span>{{ it.value }}</span>
                </p>
              </template>
              <p
                style="font-size: 0.12rem; color: #999"
                v-for="(i, index) in item.approveExecutionCommentDtoList"
              >
                <span>{{ i.candidateUserName }}评论：{{ i.comments }}</span>
              </p>
              <div
                class="comment-file"
                v-if="
                  item.dealActionVo &&
                  item.dealActionVo.fileList &&
                  item.dealActionVo.fileList.length > 0
                "
              >
                <van-image
                  v-for="(i, index) in changeFileList(
                    item.dealActionVo.fileList
                  ).imgList"
                  :key="i.fileId"
                  width=".48rem"
                  height=".48rem"
                  :src="i.fileUrl"
                  @click="
                    ImagePreview(
                      changeFileList(item.dealActionVo.fileList).imgViewList,
                      index
                    )
                  "
                />
                <div
                  class="comment-a"
                  v-for="i in changeFileList(item.dealActionVo.fileList)
                    .fileList"
                  :key="i.fileId"
                  @click="onFileDownload(i.fileUrl)"
                >
                  <span class="comment-del">{{ i.fileName }}</span>
                </div>
              </div>
            </div>
            <div class="list-status" v-else>
              <p>
                <span>
                  {{ item.userName }}
                  添加了评论
                </span>
                <span>{{ formatDate(item.executionTime, 5) }}</span>
              </p>
              <p class="comment-p">
                <span class="comment-text">{{ item.content }}</span>
                <span
                  class="comment-del"
                  v-if="item.userId == mobile"
                  @click="onDelComment(item.commentId)"
                  >撤回</span
                >
              </p>
              <div class="comment-file">
                <van-image
                  v-for="(i, index) in item.imgList"
                  :key="i.attId"
                  width=".48rem"
                  height=".48rem"
                  :src="i.url"
                  @click="onImagePreview(item.imgList, index)"
                />
                <div
                  class="comment-doc"
                  v-for="i in item.fileList"
                  :key="i.attId"
                  @click="onFileDownload(i.url)"
                >
                  <van-image width=".4rem" height=".4rem" :src="fileImgSrc" />
                  <span>{{ i.attName }}</span>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <van-empty v-else description="暂无数据" />
      </div>
    </section>
    <!-- 审批按钮 -->
    <section>
      <div
        class="approve-btn"
        v-if="taskList.approveStatus == 0 || taskList.approveStatus == 3"
      >
        <van-button
          plain
          block
          type="info"
          class="btn-blue"
          v-if="taskList.myExecutionTask && processType == 90"
          @click="getReBack"
          >回退
        </van-button>
        <template>
          <van-button
            plain
            block
            type="info"
            class="btn-blue"
            v-if="taskList.myExecutionTask && taskList.approveStatus == 3"
            @click="onToTransact(assigneeEventAction)"
            >去办理
          </van-button>
          <van-button
            block
            type="info"
            style="margin-right: 0.1rem"
            v-if="taskList.myExecutionTask && taskList.approveStatus == 3"
            @click="onApprove('办理完成')"
            >办理完成
          </van-button>
        </template>
      </div>
      <div
        class="approve-btn"
        v-if="taskList.approveStatus == 0 || taskList.approveStatus == 3"
      >
        <van-button
          plain
          block
          type="info"
          v-if="taskList.myApproveTask"
          @click="onApprove('撤回')"
          class="btn-blue"
          >撤回
        </van-button>
        <van-button
          plain
          block
          type="info"
          v-if="
            taskList.myExecutionTask &&
            taskList.approveStatus == 3 &&
            rejectEnable
          "
          @click="onApprove('拒绝')"
          class="btn-blue"
          >拒绝
        </van-button>
        <van-button
          plain
          block
          type="info"
          v-if="taskList.myExecutionTask && taskList.approveStatus != 3"
          @click="onApprove('拒绝')"
          class="btn-blue"
          >拒绝
        </van-button>
        <van-button
          plain
          block
          type="info"
          v-if="taskList.myExecutionTask"
          @click="onApprove('转交')"
          class="btn-blue"
          >转交
        </van-button>
        <van-button
          block
          type="info"
          style="margin-right: 0.1rem"
          v-if="taskList.myExecutionTask && taskList.approveStatus == 0"
          @click="onApprove('通过')"
          >通过</van-button
        >
      </div>
    </section>
    <!-- 全部评论 -->
    <section class="box comment" v-if="commentShow">
      <h1 class="title van-hairline--bottom">全部评论</h1>
      <div class="middle">
        <div class="comment-add van-hairline--bottom">
          <div class="comment-head">
            <van-image round width=".32rem" height=".32rem" :src="headImgSrc" />
          </div>
          <div class="comment-inp">
            <p class="comment-p">
              <span>{{ userName }}&nbsp;&nbsp;添加评论</span>
              <span class="comment-utils">
                <!-- 通知 -->
                <van-image
                  width=".2rem"
                  height=".2rem"
                  :src="atImgSrc"
                  @click="atShow = true"
                />
                <!-- 上传 -->
                <van-uploader
                  :after-read="onUpload"
                  accept=".png,.jpg,.gif,.jpeg,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.rtf,.txt"
                  :multiple="true"
                  :max-size="10240 * 1024"
                  @oversize="onOversize"
                  :before-read="beforeRead"
                >
                  <van-image width=".2rem" height=".2rem" :src="uploadImgSrc" />
                </van-uploader>
              </span>
            </p>
            <p class="comment-p">
              <van-field
                class="input-box"
                type="textarea"
                placeholder="请输入评论内容"
                v-model="commentVal"
                rows="1"
                autosize
                maxlength="100"
                show-word-limit
              />
              <van-button
                plain
                type="info"
                :disabled="!commentVal"
                @click="onAddComment"
                class="comment-sent"
                >发送
              </van-button>
            </p>
            <p
              class="comment-file comment-at"
              v-if="userIds.length"
              @click="atShow = true"
            >
              <span
                class="theme-color"
                v-for="item in userIds"
                :key="item.candidateUserId"
                >@{{ item.candidateUserName }}</span
              >
            </p>
            <div class="comment-file">
              <van-image
                v-for="(i, index) in fielList.imgList"
                :key="i.fileName"
                width=".48rem"
                height=".48rem"
                :src="i.content"
                @click="onImagePreview(fielList.imgList, index)"
              />
              <div
                class="comment-doc"
                v-for="i in fielList.fileList"
                :key="i.fileName"
                @click="onFileDownload(i.url)"
              >
                <van-image width=".4rem" height=".4rem" :src="fileImgSrc" />
                <span>{{ i.fileName }}</span>
              </div>
            </div>
            <p class="comment-p" v-if="attIds.length">
              <span></span>
              <span class="comment-del" @click="onDelFile">删除</span>
            </p>
          </div>
        </div>
        <div class="comment-foot">
          <span>已经到底了</span>
        </div>
      </div>
    </section>
    <!-- 按钮弹框 -->
    <van-dialog
      confirmButtonColor="#1989fa"
      v-model="dialogShow"
      :title="'（选填）' + dialogTitle + '原因?'"
      show-cancel-button
      @confirm="confirm"
    >
      <van-field v-model="dialogValue" label="请输入:" />
    </van-dialog>
    <!-- 通知列表 -->
    <van-popup class="box-at" v-model="atShow" position="left">
      <van-search v-model="atKw" placeholder="请搜索用户" />
      <div class="user-list">
        <van-checkbox-group v-model="userIds">
          <van-cell-group>
            <van-cell
              v-for="(item, index) in changeUserList(atKw)"
              clickable
              :key="item.candidateUserId"
              :title="item.candidateUserName"
            >
              <template #right-icon>
                <van-checkbox :name="item" ref="checkboxes" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
      </div>
    </van-popup>
    <!-- 转交列表 -->
    <van-popup class="box-at" v-model="transferShow" position="left">
      <van-search v-model="transferKw" placeholder="请搜索用户" />
      <div class="user-list">
        <van-radio-group v-model="transferUserId">
          <van-cell-group>
            <van-cell
              v-for="(item, index) in changeUserList(transferKw)"
              clickable
              :key="item.candidateUserId"
              :title="item.candidateUserName"
            >
              <template #right-icon>
                <van-radio :name="item.candidateUserId" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>
      <div class="on-transfer">
        <van-button
          block
          type="info"
          v-if="transferUserId"
          @click="onTransfer('转交')"
          >确认转交</van-button
        >
      </div>
    </van-popup>
    <!-- 弹窗 -->
    <van-popup v-model="transactShow" :style="{ width: '80%' }">
      <Transact100
        v-if="assigneeEventAction == 100"
        :taskList="taskList"
        :approveId="approveId"
        :assigneeEventAction="assigneeEventAction"
        :processType="processType"
        :executionId="agreenId"
        @reTransact="reTransact"
      >
      </Transact100>
      <Transact106
        v-if="assigneeEventAction == 106"
        :taskList="taskList"
        :approveId="approveId"
        :assigneeEventAction="assigneeEventAction"
        :processType="processType"
        :executionId="agreenId"
        @reTransact="reTransact"
      >
      </Transact106>
      <Transact112
        v-if="assigneeEventAction == 112"
        :taskList="taskList"
        :approveId="approveId"
        :assigneeEventAction="assigneeEventAction"
        :processType="processType"
        :executionId="agreenId"
        @reTransact="reTransact"
      >
      </Transact112>
      <Transact114
        v-if="assigneeEventAction == 114"
        :taskList="taskList"
        :approveId="approveId"
        :assigneeEventAction="assigneeEventAction"
        :processType="processType"
        :executionId="agreenId"
        @reTransact="reTransact"
      >
      </Transact114>
    </van-popup>
  </div>
</template>

<script>
// 工具
import { formatDate, objToOpt, changeFileList } from "@/utils/utils";
import { shrinkImage } from "shrinkpng";
// 接口
import {
  getUserInfo,
  getTaskHead,
  getContractRentPrice,
  getContractLikeResource,
  getTaskList,
  getTaskCommentInfo,
  getRevocation,
  getRefuse,
  getAgree,
  getTransfer,
  getCommentList,
  addComment,
  delComment,
  upload,
  getUserList,
  getHeadChangeFile,
  getBillDelayFile,
  getContractFileFlag,
  getWuyeFileFlag,
  getContractFileImg,
  getCommentShow,
  getApproveList,
  getBpOrderFile,
  getCheckFiles,
  getFormWorkInfo,
  getBillSplitFile,
  getFiles,
  getReBack,
  getOver,
} from "@/api/task/task";
// 对象
import {
  approveTypeObj,
  approveStatusObj,
  approveStatusPngObj,
} from "@/db/task";
import { Toast } from "vant";
import { Dialog } from "vant";
import { ImagePreview } from "vant";
import ContractSign from "./child/ContractSign";
import AccessoryFile from "./child/AccessoryFile";
import FormWorkInfo from "./child/FormWorkInfo";
import Reference from "./child/Reference";
import Transact100 from "./child/Transact100";
import Transact106 from "./child/Transact106";
import Transact112 from "./child/Transact112";
import Transact114 from "./child/Transact114";
import BackApprove from "@/components/backApprove";
export default {
  components: {
    ContractSign,
    AccessoryFile,
    FormWorkInfo,
    Reference,
    BackApprove,
    Transact100,
    Transact106,
    Transact112,
    Transact114,
  },
  data() {
    return {
      // 系统工具
      formatDate, //日期转换函数
      objToOpt, //对象转选项函数
      changeFileList,
      approveTypeObj,
      approveStatusObj,
      approveStatusPngObj,
      headImgSrc: require("@/assets/images/head.png"),
      fileImgSrc: require("@/assets/images/file.png"),
      uploadImgSrc: require("@/assets/images/upload.png"),
      atImgSrc: require("@/assets/images/at.png"),
      // 路径参数
      mobile: "", //手机号
      userName: "", //用户名
      processType: "", //审批类型
      approveId: "", //审批ID
      clueId: "", //线索ID
      spId: "", //服务商ID
      // 数据对象
      taskHead: "", //头部详情
      taskList: "", //审批列表
      taskCommentList: "", //审批评论合并列表
      commentsId: "", //发起人ID
      agreenId: "", //当前审批人人ID
      assigneeEventAction: "", //当前办理节点ID
      rejectEnable: false, //当前办理节点是否可拒绝
      // 弹框开关
      dialogShow: false, //审批弹框开关
      dialogTitle: "", //审批弹框标题
      dialogValue: "", //审批原因
      // 评论
      commentShow: true,
      commentVal: "", //评论内容
      commentList: "", //评论列表
      fielList: {
        imgList: [],
        fileList: [],
      },
      attIds: [], //文件列表
      userList: [], //用户列表
      // 通知
      atShow: false, //通知弹框开关
      atKw: "",
      userIds: [], //通知对象
      // 转交
      transferShow: false, //转交弹框开关
      transferKw: "",
      transferUserId: "", //转交用户ID
      // 合同签约
      contractFileFlag: false,
      wuyeFileFlag: false,
      contractFileImg: [],
      // 抬头变更
      approveFileList: [],
      // 关联单详情
      formWorkInfo: "",
      sellPriceList: [], //租赁价格
      sameRoomList: [], //相似房源
      // 去办理
      transactShow: false,
    };
  },
  created() {
    let { processType, approveId, clueId } = this.$route.query;
    this.processType = processType; //审批类型
    this.approveId = approveId; //审批ID
    this.clueId = clueId; //线索ID
    this.getUserInfo();
  },
  methods: {
    // 【转换】头像信息
    changeHeadImg(item) {
      let src;
      if (
        item.approveExecutionCandidateDtoList &&
        item.approveExecutionCandidateDtoList[0] &&
        item.approveExecutionCandidateDtoList[0].candidateUserHeadUrl != ""
      ) {
        return (src =
          item.approveExecutionCandidateDtoList[0].candidateUserHeadUrl);
      } else {
        return (src = require("@/assets/images/head.png"));
      }
    },

    base64Encode(str) {
      var base64EncodeChars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
      var out, i, len;
      var c1, c2, c3;
      len = str.length;
      i = 0;
      out = "";
      while (i < len) {
        c1 = str.charCodeAt(i++) & 0xff;
        if (i == len) {
          out += base64EncodeChars.charAt(c1 >> 2);
          out += base64EncodeChars.charAt((c1 & 0x3) << 4);
          out += "==";
          break;
        }
        c2 = str.charCodeAt(i++);
        if (i == len) {
          out += base64EncodeChars.charAt(c1 >> 2);
          out += base64EncodeChars.charAt(
            ((c1 & 0x3) << 4) | ((c2 & 0xf0) >> 4)
          );
          out += base64EncodeChars.charAt((c2 & 0xf) << 2);
          out += "=";
          break;
        }
        c3 = str.charCodeAt(i++);
        out += base64EncodeChars.charAt(c1 >> 2);
        out += base64EncodeChars.charAt(((c1 & 0x3) << 4) | ((c2 & 0xf0) >> 4));
        out += base64EncodeChars.charAt(((c2 & 0xf) << 2) | ((c3 & 0xc0) >> 6));
        out += base64EncodeChars.charAt(c3 & 0x3f);
      }
      return out;
    },

    // 【转换】执行状态
    changeComment(executionStatus, executionAction, nodeType) {
      let text;
      if (executionStatus == 0 && executionAction == null && nodeType != 3) {
        text = '<span style="color:#909399">(待审批)</span>';
      } else if (
        executionStatus == 1 &&
        executionAction == null &&
        nodeType != 3
      ) {
        text = `<span style="color:#E6A23C">(审批中)</span>`;
      } else if (
        executionStatus == 0 &&
        executionAction == null &&
        nodeType == 3
      ) {
        text = '<span style="color:#909399">(待办理)</span>';
      } else if (
        executionStatus == 1 &&
        executionAction == null &&
        nodeType == 3
      ) {
        text = `<span style="color:#E6A23C">(办理中)</span>`;
      } else if (executionStatus == 2 && executionAction == 0) {
        text = '<span style="color:#009688">(已提交)</span>';
      } else if (executionStatus == 2 && executionAction == 1) {
        text = '<span style="color:#409EFF">(已通过)</span>';
      } else if (executionStatus == 2 && executionAction == 2) {
        text = '<span style="color:#FF5722">(已拒绝)</span>';
      } else if (executionStatus == 2 && executionAction == 3) {
        text = "(已转交)";
      } else if (executionStatus == 2 && executionAction == 4) {
        text = "(已退回)";
      } else if (executionStatus == 2 && executionAction == 5) {
        text = '<span style="color:#909399">(已撤回)</span>';
      } else if (executionStatus == 3) {
        text = "(已回退)";
      }
      return text;
    },

    // 【转换】通知对象
    changeUserIds(userIds) {
      let arr = [];
      for (let i of userIds) {
        arr = [...arr, i.candidateUserId];
      }
      return arr;
    },

    // 【转换】用户列表
    changeUserList(kw) {
      let list = [];
      this.userList.forEach((i) => {
        if (JSON.stringify(i).search(kw) != -1) {
          list.push(i);
        }
      });
      return list;
    },

    // 【请求】获取用户信息
    getUserInfo() {
      getUserInfo().then((res) => {
        if (res.success) {
          this.mobile = res.data.mobile;
          this.userName = res.data.empInfo.fullName;
          this.spId = res.data.spInfo.spId;
          this.taskHead = "";
          this.formWorkInfo = "";
          this.getTaskHead(); // 【请求】审批头部信息
          this.getContractRentPrice(); // 【请求】租赁价格表格
          this.getContractLikeResource(); // 【请求】相似房源表格
          this.getTaskList(); // 【请求】审批进度信息
          this.getTaskCommentInfo(); //【请求】获取审批评论合并列表
          this.getCommentList(); // 【请求】获取评论列表
          this.getUserList(); //【请求】获取通知转交用户列表
          this.getCommentShow(); //【请求】获取是否展示评论区
        }
      });
    },

    // 【请求】审批头部信息
    getTaskHead() {
      let data = {
        processType: this.processType,
        approveId: this.approveId,
      };
      getTaskHead(data).then((res) => {
        this.taskHead = res.data;
        this.getContractFileFlag(); //【请求】查询是否有合同附件
        this.getWuyeFileFlag(); //【请求】查询是否有合同附件
        this.getFiles(); //获取附件
        if (this.taskHead.relateFormId) {
          this.getFormWorkInfo();
        }
      });
    },

    // 【请求】租赁价格表格
    getContractRentPrice() {
      let data = {
        approveId: this.approveId,
        processType: this.processType,
        contractId: this.taskHead.contractId,
      };
      getContractRentPrice(data).then((res) => {
        if (res.success) {
          this.sellPriceList = res.data;
        }
      });
    },

    // 【请求】相似房源表格
    getContractLikeResource() {
      let data = {
        approveId: this.approveId,
        processType: this.processType,
        contractId: this.taskHead.contractId,
      };
      getContractLikeResource(data).then((res) => {
        if (res.success) {
          this.sameRoomList = res.data;
        }
      });
    },

    // 【请求】获取账单延期附件
    getFiles() {
      let data = {
        approveId: this.approveId,
        processType: this.processType,
      };
      getFiles(data).then((res) => {
        if (res.success) {
          this.approveFileList = res.data;
        }
      });
    },

    // 【请求】审批进度信息
    getTaskList() {
      let data = {
        approveId: this.approveId,
        spId: this.spId,
      };
      getTaskList(data).then((res) => {
        if (res.data) {
          let data = res.data;
          let taskList = data.newApproveVoList;
          if (taskList && taskList.length > 0) {
            taskList.pop(); //删除结束节点
            this.commentsId = taskList[0].executionId; //获取发起人ID
            for (let i of taskList) {
              if (i.executionStatus == 1) {
                this.agreenId = i.executionId; //获取当前审批人ID
                this.assigneeEventAction = i.assigneeEventAction; //获取当前审批人ID
                this.rejectEnable = i.rejectEnable; //获取当前审批节点是否可拒绝
              }
            }
          }
          this.taskList = data;
        }
      });
    },

    //【请求】获取审批评论合并列表
    getTaskCommentInfo() {
      let data = {
        approveId: this.approveId,
        spId: this.spId,
      };
      getTaskCommentInfo(data).then((res) => {
        if (res.success) {
          if (res.data && res.data.length > 0) {
            this.taskCommentList = res.data;
          }
        }
      });
    },

    // 【请求】是否展示评论区
    getCommentShow() {
      let data = {
        approveId: this.approveId,
      };
      getCommentShow(data).then((res) => {
        if (res.success) {
          this.commentShow = res.data;
        }
      });
    },

    // 【请求】获取评论列表
    getCommentList() {
      let data = {
        topicId: this.approveId,
      };
      getCommentList(data).then((res) => {
        if (res.success) {
          if (res.data) {
            for (let i of res.data) {
              for (let j of i.fileList) {
                j.url =
                  "http://pdf.zkww.net/onlinePreview?url=" +
                  encodeURIComponent(this.base64Encode(j.url));
              }
            }
          }
          this.commentList = res.data;
        }
      });
    },

    // 【请求】查询是否有合同附件
    getContractFileFlag() {
      let data = {
        contractId: this.taskHead.contractId,
      };
      getContractFileFlag(data).then((res) => {
        if (res.success) {
          this.contractFileFlag = true;
        } else {
          this.contractFileFlag = false;
        }
      });
    },

    // 【请求】查询是否有合同附件
    getWuyeFileFlag() {
      let data = {
        contractId: this.taskHead.contractId,
      };
      getWuyeFileFlag(data).then((res) => {
        if (res.success) {
          this.wuyeFileFlag = true;
        } else {
          this.wuyeFileFlag = false;
        }
      });
    },

    // 【请求】获取合同附件图片
    getContractFileImg() {
      let data = {
        contractId: this.taskHead.contractId,
      };
      getContractFileImg(data).then((res) => {
        if (res.success) {
          let arr = [];
          for (let i of res.data) {
            arr = [...arr, i.filePath];
          }
          this.contractFileImg = arr;
        }
      });
    },

    // 【请求】获取账单延期附件
    getBillDelayFile() {
      let data = {
        approveId: this.approveId,
      };
      getBillDelayFile(data).then((res) => {
        if (res.success) {
          this.approveFileList = res.data;
        }
      });
    },

    // 【请求】获取账单拆分
    getBillSplitFile() {
      let data = {
        approveId: this.approveId,
      };
      getBillSplitFile(data).then((res) => {
        if (res.success) {
          this.approveFileList = res.data;
        }
      });
    },

    // 【请求】获取抬头变更附件
    getHeadChangeFile() {
      let data = {
        contractId: this.taskHead.contractId,
      };
      getHeadChangeFile(data).then((res) => {
        if (res.success) {
          this.approveFileList = res.data;
        }
      });
    },

    // 【请求】获取积分赠送附件
    getBpOrderFile() {
      let data = {
        approveId: this.approveId,
      };
      getBpOrderFile(data).then((res) => {
        if (res.success) {
          this.approveFileList = res.data;
        }
      });
    },

    // 【请求】获取工单附件
    getCheckFiles() {
      let data;
      if (
        this.processType == 5 ||
        this.processType == 30 ||
        this.processType == 31
      ) {
        data = {
          processType: this.processType,
          applyApproveId: this.approveId,
        };
      } else if (this.processType == 12) {
        data = {
          processType: this.processType,
          finishApproveId: this.approveId,
        };
      } else if (this.processType == 32) {
        data = {
          processType: this.processType,
          delayApproveId: this.approveId,
        };
      }
      getCheckFiles(data).then((res) => {
        if (res.success) {
          this.approveFileList = res.data;
        }
      });
    },

    // 【请求】关联工单详情
    getFormWorkInfo() {
      let data = {
        workFormId: this.taskHead.relateFormId,
      };
      getFormWorkInfo(data).then((res) => {
        if (res.success) {
          this.formWorkInfo = res.data;
        }
      });
    },

    // 【请求】获取待我审批列表
    getApproveList() {
      let data = {
        pageNo: 1,
        pageSize: 10,
        timeType: 1,
        taskType: 1,
        processType: "",
        approveStatus: "0",
      };
      getApproveList(data).then((res) => {
        let data = res.data.content;
        this.approveId = data[0].approveId;
        this.processType = data[0].processType;
        this.getUserInfo();
      });
    },

    // 【监听】审批操作完成
    getNext() {
      Dialog.confirm({
        title: "审批",
        confirmButtonText: "下一条",
        cancelButtonText: "取消",
      }).then(() => {
        this.commentList = [];
        this.getApproveList();
      });
    },

    // 【监听】审批撤回
    getRevocation() {
      let data = {
        executionId: this.commentsId, //发起人ID
      };
      getRevocation(data).then((res) => {
        Toast(res.msg);
        this.getTaskList();
        this.getTaskCommentInfo(); //【请求】获取审批评论合并列表
      });
    },

    // 【监听】审批拒绝
    getRefuse() {
      let data = {
        executionId: this.agreenId, //当前审核人id
        comments: this.dialogValue, //拒绝原因
      };
      getRefuse(data).then((res) => {
        if (res.success) {
          Toast(res.msg);
          this.getTaskList();
          this.getTaskCommentInfo(); //【请求】获取审批评论合并列表
          this.getNext();
        } else {
          Toast(res.msg);
        }
      });
    },

    // 【监听】审批同意
    getAgree() {
      let data = {
        executionId: this.agreenId, //当前审核人id
        comments: this.dialogValue, //同意原因
      };
      getAgree(data).then((res) => {
        if (res.success) {
          Toast(res.msg);
          this.getTaskList();
          this.getTaskCommentInfo(); //【请求】获取审批评论合并列表
          this.getNext();
        } else {
          Toast(res.msg);
        }
      });
    },

    // 【监听】办理完成
    getOver() {
      let data = {
        approveId: this.approveId,
        processType: this.processType,
        executionId: this.agreenId,
        comments: this.dialogValue,
      };
      getOver(data).then((res) => {
        if (res.success) {
          Toast(res.msg);
          this.getTaskList();
          this.getTaskCommentInfo();
        } else {
          Toast(res.msg);
        }
      });
    },

    // 【监听】审批转交
    getTransfer() {
      let data = {
        executionId: this.agreenId, //当前审核人id
        transferUserId: this.transferUserId, //转交人id
      };
      getTransfer(data).then((res) => {
        if (res.success) {
          Toast(res.msg);
          this.getTaskList();
          this.getTaskCommentInfo(); //【请求】获取审批评论合并列表
          this.transferShow = false;
          this.getNext();
        } else {
          Toast(res.msg);
        }
      });
    },

    // 【监听】审批操作
    onApprove(title) {
      if (title == "撤回") {
        this.getRevocation();
      } else if (title == "转交") {
        this.transferShow = true;
      } else {
        this.dialogShow = true;
        this.dialogValue = "";
        this.dialogTitle = title;
      }
    },

    // 【监听】通过拒绝确认按钮
    confirm: _.throttle(
      function () {
        if (this.dialogTitle == "通过") {
          this.getAgree();
        }
        // 拒绝
        else if (this.dialogTitle == "拒绝") {
          this.getRefuse();
        }
        // 办理完成
        else if (this.dialogTitle == "办理完成") {
          this.getOver();
        }
      },
      3000,
      {
        trailing: false,
      }
    ),

    // 【监听】转交确认按钮
    onTransfer: _.throttle(
      function () {
        this.getTransfer();
      },
      3000,
      {
        trailing: false,
      }
    ),

    // 【监听】发送评论
    onAddComment() {
      let data = {
        userInfoDto: {
          username: this.userName,
          tel: this.mobile,
        },
        approveId: this.approveId,
        content: this.commentVal,
        url: window.location.href,
        attIds: this.attIds.join(","), //附件信息
        userIds: this.changeUserIds(this.userIds), //通知对象
      };
      addComment(data).then((res) => {
        if (res.success) {
          this.commentVal = "";
          this.getCommentList();
          this.getTaskCommentInfo(); //【请求】获取审批评论合并列表
          this.onDelFile();
          this.userIds = [];
          Toast("评论成功");
        }
      });
    },

    // 【监听】删除预览附件
    onDelFile() {
      this.fielList = {
        imgList: [],
        fileList: [],
      };
      this.attIds = [];
    },

    // 【监听】撤回评论
    onDelComment(commentId) {
      Dialog.confirm({
        title: "是否撤回当前评论？",
        confirmButtonColor: "#1989fa",
        confirmButtonText: "是",
        cancelButtonText: "否",
      }).then(() => {
        let data = {
          commentId,
          userInfoDto: {
            tel: this.mobile,
          },
        };
        delComment(data).then((res) => {
          if (res.success) {
            this.getCommentList();
            this.getTaskCommentInfo(); //【请求】获取审批评论合并列表
            Toast("撤回成功");
          }
        });
      });
    },

    // 【监听】图片预览
    onImagePreview(images, index) {
      let imageList = [];
      for (let i of images) {
        imageList = [...imageList, i.url];
      }
      ImagePreview({
        images: imageList,
        startPosition: index,
      });
    },

    ImagePreview(images, index) {
      ImagePreview({
        images: images,
        startPosition: index,
      });
    },

    // 【监听】文件下载
    onFileDownload(url) {
      window.open(url);
    },

    // 【请求】通知列表
    getUserList() {
      let data = {
        kw: this.kw,
      };
      getUserList(data).then((res) => {
        if (res.success) {
          this.userList = res.data;
        }
      });
    },

    // 【监听】图片上传
    onUpload(fiel) {
      let fielArr = [].concat(fiel);
      for (let i of fielArr) {
        i.url = i.content;
        i.fileName = i.file.name;
        if (i.file.type.split("/", 1).toString() == "image") {
          i.fileType = 1;
        } else {
          i.fileType = 2;
        }
      }
      upload(fielArr).then((res) => {
        if (res.isSuccess == "yes") {
          for (let i of fielArr) {
            if (i.fileType == 1) {
              this.fielList.imgList = [...this.fielList.imgList, i];
            } else {
              this.fielList.fileList = [...this.fielList.fileList, i];
            }
          }
          this.attIds = [...this.attIds, ...res.data];
          Toast("上传成功");
        } else {
          Toast("上传失败");
        }
      });
    },
    onOversize(file) {
      Toast("文件大小不能超过 10MB");
    },
    beforeRead(file) {
      return shrinkImage(file, {
        quality: 15,
      });
    },

    // 【监听】回退
    getReBack() {
      Dialog.confirm({
        title: "回退",
        message: "是否要回退",
      }).then(() => {
        let data = {
          approveId: this.approveId,
          processType: this.processType,
          executionId: this.agreenId, //当前审核人id
        };
        getReBack(data).then((res) => {
          if (res.success) {
            Toast.success(res.msg);
            this.getTaskList(); // 【请求】审批进度信息
            this.getTaskCommentInfo(); //【请求】获取审批评论合并列表
          } else {
            Toast.fail(res.msg);
          }
        });
      });
    },

    // 【监听】去办理
    onToTransact(assigneeEventAction) {
      let arr = [100, 106, 112, 114];
      if (arr.find((i) => i == assigneeEventAction)) {
        this.transactShow = true;
      } else {
        Toast("请去电脑端进行办理操作");
      }
    },

    // 【监听】办理完成
    reTransact() {
      this.transactShow = false;
      this.getTaskList(); // 【请求】审批进度信息
      this.getTaskCommentInfo(); //【请求】获取审批评论合并列表
    },
  },
};
</script>


<style lang="scss" scoped>
@import "~@/assets/styles/theme";

#taskInfo {
  display: flex;
  flex-direction: column;
  background: #f1f2f6;
}

// 布局外壳
.box {
  margin-bottom: 0.15rem;
  background: #fff;
  position: relative;

  .title {
    padding: 0.1rem;
    font-size: 0.16rem;
  }

  .middle {
    padding: 0.2rem;
  }
}

.approve-png {
  position: absolute;
  top: 0.1rem;
  right: 0.2rem;
}

// 头部详细
.mid-item {
  margin-bottom: 0.1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-title {
  color: #999;
  font-size: 0.14rem;
}

.item-text {
  font-size: 0.14rem;
  max-width: 60%;
}

// 审批列表
.list-item {
  display: flex;

  .list-img {
    padding-bottom: 0.2rem;
    margin-right: 0.1rem;
    position: relative;
  }

  .line::before {
    content: "";
    width: 0.01rem;
    height: calc(98% - 0.32rem);
    background: #999999;
    opacity: 0.3;
    position: absolute;
    top: 0.34rem;
    right: 0.16rem;
  }

  .list-status {
    font-size: 0.15rem;
    width: 100%;
    margin-bottom: 0.15rem;
    padding-bottom: 0.15rem;

    p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 0.2rem;
    }
  }
}

// 审批按钮
.approve-btn {
  display: flex;
  justify-content: space-around;
  background: #f1f2f6;
  padding-left: 0.1rem;
}

.btn-blue {
  background: rgba(37, 137, 255, 0.1);
  margin: 0 0.1rem 0.15rem 0;
}

// 评论
.comment {
  font-size: 0.15rem;
  margin: 0;

  // 头像
  .comment-head {
    margin-right: 0.1rem;
  }

  // 段落
  .comment-p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.1rem;
  }

  // 上传@
  .comment-utils {
    display: flex;
    width: 0.58rem;
    justify-content: space-around;
  }

  // 日期
  .comment-date {
    color: #999;
    font-size: 0.13rem;
  }

  // 评论输入框
  .comment-add {
    display: flex;
    margin-bottom: 0.1rem;
  }

  .comment-inp {
    flex: 1;
    display: flex;
    flex-direction: column;

    .input-box {
      flex: 1;
      background-color: #f1f2f6;
      border-radius: 0.1rem;
      padding: 0.1rem;
      border: 0;
    }

    .comment-sent {
      border: 0;
      margin: 0;
      color: #409eff;
      background: #ecf5ff;
      border-color: #b3d8ff;
      margin-left: 0.1rem;
      height: 100%;
      font-size: 0.16rem;
      padding: 0 0.1rem;
      border-radius: 0.1rem;
    }
  }

  // 评论列表
  .comment-ul {
    display: flex;
    flex-direction: column;
  }

  .comment-li {
    display: flex;
    margin-bottom: 0.1rem;
  }

  .comment-body {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .comment-at {
    padding: 0.05rem;
    border-radius: 0.05rem;

    > span {
      margin: 0.05rem;
    }
  }

  // 底部
  .comment-foot {
    padding: 0.1rem 0;
    text-align: center;

    span {
      font-size: 0.12rem;
      color: rgba(153, 153, 153, 1);
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0.12rem;
      padding: 0.05rem 0.1rem;
    }
  }

  // 附件背景
  .comment-file {
    background: #f1f2f6;
    margin-bottom: 0.1rem;

    .van-image {
      margin: 0.05rem;
    }

    .comment-doc {
      display: flex;
      align-items: center;
    }
  }
}

// 通知
.box-at {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;

  .van-search {
    height: 0.54rem;
  }
}

.user-list {
  height: calc(100% - 0.54rem);
  overflow: auto;
}

.comment-del {
  color: #4f94f3;
  font-size: 0.13rem;
}

.comment-text {
  color: #666;
  font-size: 0.14rem;
  margin: 0.05rem 0;
  max-width: 80%;
}

.comment-a {
  background: #f0f2f5;
  padding: 0.1rem;
}

.font-12 {
  font-size: 0.12rem;
}

.item-text-p {
  margin: 0.05rem 0;
  padding: 0.05rem 0;
}

.item-text-p:first-child {
  margin: 0;
  padding: 0;
}
</style>