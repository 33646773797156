<template>
  <div class="reference">
    <h1 class="title van-hairline--bottom">
      {{ title }}
    </h1>
    <div class="middle">
      <van-button type="info" @click="priceShow = true">租赁价格</van-button>
      <van-button type="info" @click="roomShow = true">相似房源</van-button>
      <van-button type="info" @click="onClueInfo">线索详情</van-button>
    </div>
    <!-- 租赁价格弹框 -->
    <van-popup class="popup" v-model:show="priceShow" position="right">
      <van-nav-bar title="租赁价格" left-text="返回" left-arrow @click-left="priceShow = false" />
      <li class="li" v-for="item in sellPriceList">
        <p class="li-item">
          <span>合同年</span>
          <span>
            {{ item.startDate || "-" }}~{{ item.endDate || "-" }}
          </span>
        </p>
        <p class="li-item">
          <span>免租日期</span>
          <span class="item-span">
            <span v-for="it in item.discountInfoDtoList">
              {{ it.discountStartDate }}~{{ it.discountEndDate }}
            </span>
          </span>
        </p>
        <p class="li-item">
          <span>免租金额</span>
          <span>
            {{ item.discountAmount || "-" }}
          </span>
        </p>
        <p class="li-item">
          <span>递增点</span>
          <span v-for="it in item.increaseInfoDtoList">
            {{ it.increaseDate || "-" }}
          </span>
        </p>
        <p class="li-item">
          <span>单价递增</span>
          <span v-for="it in item.increaseInfoDtoList">
            {{ it.increaseRate }}
            <span v-if="it.increaseType == 1">%</span>
            <span v-if="it.increaseType == 2">元</span>
          </span>
        </p>
        <p class="li-item">
          <span>签约净价(元/㎡/天)</span>
          <span>
            {{ item.signRealPrice || "-" }}
          </span>
        </p>
        <p class="li-item">
          <span>签约实价(元/㎡/天)</span>
          <span>
            {{ item.signActualPrice || "-" }}
          </span>
        </p>
      </li>
    </van-popup>
    <!-- 相似房源弹框 -->
    <van-popup class="popup" v-model:show="roomShow" position="right">
      <van-nav-bar title="相似房源" left-text="返回" left-arrow @click-left="roomShow = false" />
      <li class="li" v-for="item in sameRoomList">
        <p class="li-item">
          <span>签约房源</span>
          <span>
            {{ item.unitResource || "-" }}
          </span>
        </p>
        <p class="li-item">
          <span>租赁面积</span>
          <span>
            {{ item.quantity || "-" }}
          </span>
        </p>
        <p class="li-item">
          <span>基准价(元/㎡/天)</span>
          <span>
            {{ item.baseAvgPrice || "-" }}
          </span>
        </p>
        <p class="li-item">
          <span>签约单价(元/㎡/天)</span>
          <span>
            {{ item.signUnitPrice || "-" }}
          </span>
        </p>
        <p class="li-item">
          <span>签约净价(元/㎡/天)</span>
          <span>
            {{ item.signRealPrice || "-" }}
          </span>
        </p>
        <p class="li-item">
          <span>签约日期</span>
          <span>
            {{ item.signToDate || "-" }}
          </span>
        </p>
      </li>
    </van-popup>
    <!-- 线索详情 -->
    <ClueInfo :clueId="clueId" ref="clueInfo"></ClueInfo>
  </div>
</template>

<script>
import ClueInfo from "@/views/clue/child/ClueInfo";
export default {
  name: "Reference",
  props: ['title', 'clueId', 'sellPriceList', 'sameRoomList'],
  components: { ClueInfo },
  data() {
    return {
      priceShow: false,
      roomShow: false,
    }
  },
  methods: {
    // 【监听】线索详情
    onClueInfo() {
      this.$refs.clueInfo.init();
    }
  }
};
</script>
<style lang="scss" scoped>
.reference {
  margin-bottom: 0.15rem;
  background: #fff;
}

.title {
  padding: 0.1rem;
  font-size: 0.16rem;
}

.middle {
  padding: 0.15rem;
  display: flex;
  justify-content: space-around;
}

.popup {
  width: 100%;
  height: 100%;
  background: #f1f2f6;
}

.li {
  margin: .15rem 0;
  background: #fff;
}

.li-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .05rem .15rem;
  flex-wrap: wrap;
}

.van-nav-bar {
  background: #1989fa;
}

.item-span {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
</style>

<style lang="scss">
.reference {

  .van-nav-bar .van-icon,
  .van-nav-bar__text,
  .van-nav-bar__title {
    color: #fff !important;
    font-size: .18rem;
  }
}
</style>