<template>
  <div id="ContractSign">
    <h1 class="title van-hairline--bottom">
      {{ title }}
    </h1>
    <div class="middle">
      <div class="img-list">
        <van-image class="img-item" width=".48rem" height=".48rem" v-for="(i, index) in contractFileImg" :key="index"
          :src="i" @click="onImagePreview(contractFileImg, index)" />
      </div>
      <div class="download" v-if="processType == 1">
        <van-button size="small" plain type="info" v-if="taskHead.contractType == 1"
          @click="downloads(1)">租赁合同</van-button>
        <van-button size="small" plain type="info" v-if="taskHead.contractType == 2"
          @click="downloads(6)">续租合同</van-button>
        <van-button size="small" plain type="info" v-if="taskHead.contractType == 3"
          @click="downloads(7)">滞留合同</van-button>
        <van-button size="small" plain type="info" @click="downloads(2)">租赁账单</van-button>
        <van-button size="small" plain type="info" v-if="taskHead.econtractType == 2"
          @click="downloads(4)">物业合同</van-button>
        <van-button size="small" plain type="info" v-if="taskHead.econtractType == 2"
          @click="downloads(5)">物业账单</van-button>
        <van-button size="small" plain type="info" @click="downloads(3)" v-if="contractFileFlag">租赁合同附件</van-button>
        <van-button size="small" plain type="info" @click="downloads(8)" v-if="wuyeFileFlag">物业合同附件</van-button>
      </div>
      <div class="download" v-if="processType == 80">
        <van-button size="small" plain type="info" @click="downloads(9)">预定合同</van-button>
        <van-button size="small" plain type="info" @click="downloads(10)">预定账单</van-button>
      </div>
      <div class="bill-info" v-if="processType == 1">
        <van-button block plain type="info" @click="billShow = true">查看合同账单明细</van-button>
      </div>
    </div>
    <!-- 费用明细弹框--->
    <van-popup v-model:show="billShow" position="right" class="bill-table">
      <!-- 明细标题 -->
      <div class="nav">
        <span class="back" @click="billShow = false"><van-icon name="arrow-left" />返回</span>
        <span>账单明细</span>
      </div>
      <!-- 明细内容 -->
      <div class="bill-list">
        <div class="bill-head">
          <span>履约保证金</span><span>￥{{
            taskHead.contrectFeeMap && taskHead.contrectFeeMap.performanceBond
          }}</span>
        </div>
        <li v-for="item in taskHead.contrectFeeMap &&
          taskHead.contrectFeeMap.periodNumFeeMapList" :key="item.periodNum" class="bill-item">
          <p class="bill-top">
            <span>第{{ item.periodNum }}期({{ item.finalUnitPrice }})</span>
            <span>{{ formatDate(item.cycleStart) }} ~
              {{ formatDate(item.cycleEnd) }}</span>
          </p>
          <p class="bill-mid">
            <span>租金：￥{{ item.periodNumRental }}</span>
            <span>物业费：￥{{ item.periodNumProperty }}</span>
          </p>
          <p class="bill-mid" v-for="(i, index) in item.increaseList" :key="index">
            <span>{{ i.exacctId == 1 ? "租金" : "物业" }}递增点</span>
            <span>
              {{ formatDate(i.increaseDate) }} :
              {{
                i.incrementUnit == 1 ? i.increment + "%" : "￥" + i.increment
              }}</span>
          </p>
          <van-collapse v-model="activeNames" border>
            <van-collapse-item title="详情" :name="item.periodNum">
              <p class="bill-bottom" v-for="(i, index) in item.feeList" :key="index">
                <span>{{ i.exacctName }}：￥{{ i.finalAmount }}</span><span>{{ formatDate(i.sectionStart) }}~{{
                  formatDate(i.sectionEnd)
                }}</span>
              </p>
            </van-collapse-item>
          </van-collapse>
        </li>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { formatDate } from "@/utils/utils";
import { approveTypeObj } from "@/db/task";
import { ImagePreview } from "vant";
export default {
  name: "ContractSign",
  props: ["processType", "taskHead", "title", "contractFileFlag", "wuyeFileFlag", "contractFileImg"],
  data() {
    return {
      formatDate,
      approveTypeObj,
      billShow: false,
      activeNames: ["0"],
    };
  },
  computed: {
    type() {
      if (this.taskHead.econtractType == 1) {
        return 1;
      } else {
        return 3;
      }
    },
  },
  methods: {
    // 【监听】电子合同下载
    downloads(n) {
      // const path = "/apg/finance/contract/downLoad";
      const path = "http://nm.eastecp.com/apg/finance/contract/downLoad";
      const { contractId, reserveContractId } = this.taskHead;
      let urlobj = {
        1:
          path +
          "/htmlToImage?contractId=" +
          contractId +
          "&contractModelType=1",
        6:
          path +
          "/htmlToImage?contractId=" +
          contractId +
          "&contractModelType=4",
        7:
          path +
          "/htmlToImage?contractId=" +
          contractId +
          "&contractModelType=6",
        2: path + "/feeToImage?contractId=" + contractId + "&type=" + this.type,
        3:
          path +
          "/additionalDownLoad?contractId=" +
          contractId +
          "&contractAttachType=2",
        8:
          path +
          "/additionalDownLoad?contractId=" +
          contractId +
          "&contractAttachType=6",
        4:
          path +
          "/htmlToImage?contractId=" +
          contractId +
          "&contractModelType=2",
        5: path + "/feeToImage?contractId=" + contractId + "&type=2",
        9:
          path +
          "/reserveHtmlToImage?reserveContractId=" +
          reserveContractId +
          "&contractModelType=9",
        10:
          path +
          "/reserveFeeToImage?reserveContractId=" +
          reserveContractId +
          "&contractModelType=9",
      };
      let url = urlobj[n];
      console.log('url: ', url);
      if (n == 3 || n == 8) {
        window.location.href = [url];
      } else {
        ImagePreview([url]);
      }
    },
    // 【监听】图片预览
    onImagePreview(images, index) {
      ImagePreview({
        images,
        startPosition: index,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#ContractSign {
  margin-bottom: 0.15rem;
  background: #fff;
  position: relative;

  .title {
    padding: 0.1rem;
    font-size: 0.16rem;
  }

  .middle {
    padding: 0.15rem;
  }
}

.download {
  display: flex;
  flex-wrap: wrap;

  .van-button--plain {
    margin: 0 0.05rem 0.05rem 0;
    background: rgba(37, 137, 255, 0.1);
    height: 0.32rem;
    min-width: .8rem;
    font-size: 0.14rem;
  }
}

.bill-info {
  margin-top: 0.1rem;

  .van-button--plain {
    background: rgba(37, 137, 255, 0.1);
    height: 0.36rem;
    font-size: 0.14rem;
  }
}

.bill-table {
  width: 100%;
  height: 100%;
  background: #f1f2f6;
  display: flex;
  flex-direction: column;
}

.nav {
  height: 0.48rem;
  background-color: #1989fa;
  font-size: 0.16rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.back {
  cursor: pointer;
  height: 0.48rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: #fff;
  top: 0;
  left: 0.1rem;
}

.bill-head {
  font-size: 0.14rem;
  padding: 0.05rem 0.1rem;
  margin-bottom: 0.1rem;
  background: #1989fa;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.05rem;
}

.bill-list {
  flex: 1;
  padding: 0.1rem;
}

.bill-top {
  padding: 0.05rem 0.1rem;
  font-size: 0.14rem;
  background: #1989fa;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bill-item {
  margin: 0.1rem 0;
}

.bill-mid {
  padding: 0.05rem 0.1rem;
  font-size: 0.14rem;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bill-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.van-collapse-item {
  text-align: center;
}

.img-item {
  margin: 0 0.05rem 0.1rem 0;
}
</style>
