import { render, staticRenderFns } from "./ClueInfo.vue?vue&type=template&id=086ece21&scoped=true"
import script from "./ClueInfo.vue?vue&type=script&lang=js"
export * from "./ClueInfo.vue?vue&type=script&lang=js"
import style0 from "./ClueInfo.vue?vue&type=style&index=0&id=086ece21&prod&lang=scss&scoped=true"
import style1 from "./ClueInfo.vue?vue&type=style&index=1&id=086ece21&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "086ece21",
  null
  
)

export default component.exports