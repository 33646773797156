<template>
  <div class="clue-info">
    <van-popup class="popup" v-model:show="showFlag" position="right">
      <van-nav-bar title="线索详情" left-text="返回" left-arrow @click-left="showFlag = false" />
      <section class="text-item">
        <h1 class="van-hairline--bottom"><i class="text-icon"></i>客户详情</h1>
        <div class="text-content">
          <p class="info-item">
            <span class="info-title">客户名称：</span>
            <span>{{ clueInfoDto.tenantName }}</span>
          </p>
          <p class="info-item">
            <span class="info-title">联系人：</span>
            <span>{{ clueInfoDto.tenantUserName }}</span>
          </p>
          <p class="info-item">
            <span class="info-title">联系电话：</span>
            <span>{{ clueInfoDto.tenantUserMobile }}</span>
          </p>
          <p class="info-item">
            <span class="info-title">客户状态：</span>
            <span>{{ clueInfoDto.clueStatusName }}</span>
          </p>
          <p class="info-item">
            <span class="info-title">客户等级：</span>
            <span>{{ clueInfoDto.turnoverOddsName }}</span>
          </p>
          <p class="info-item">
            <span class="info-title">客户行业：</span>
            <span>{{ clueInfoDto.industryName }}</span>
          </p>
          <p class="info-item">
            <span class="info-title">意向园区：</span>
            <span>{{ clueInfoDto.projectName }}</span>
          </p>
          <p class="info-item">
            <span class="info-title">需求面积：</span>
            <span>{{ clueInfoDto.requiredAreaRangeName }}</span>
          </p>
          <p class="info-item">
            <span class="info-title">意向区域：</span>
            <span>{{ clueInfoDto.hotAreaName }}</span>
          </p>
          <p class="info-item">
            <span class="info-title">价格期望：</span>
            <span>{{ clueInfoDto.requiredUnitPriceMin }}</span>
            <span>-</span>
            <span>{{ clueInfoDto.requiredUnitPriceMax }}</span>
            <span v-if="clueInfoDto.requiredPricingUnitName">({{ clueInfoDto.requiredPricingUnitName }})</span>
          </p>
          <p class="info-item">
            <span class="info-title">招商部门：</span>
            <span>{{ clueInfoDto.deptName }}</span>
          </p>
          <p class="info-item">
            <span class="info-title">招商经理：</span>
            <span>{{ clueInfoDto.consultantName }}</span>
          </p>
          <p class="info-item">
            <span class="info-title">来源渠道：</span>
            <span>{{ clueInfoDto.channelTypeName }}</span>
          </p>
          <p class="info-item">
            <span class="info-title">合伙人：</span>
            <span>{{ clueInfoDto.partnerName }}</span>
          </p>
          <p class="info-item">
            <span class="info-title">需求备注：</span>
            <span>{{ clueInfoDto.remark }}</span>
          </p>
        </div>
      </section>
      <section class="text-item">
        <h1 class="van-hairline--bottom"><i class="text-icon"></i>客户时间线</h1>
        <div class="text-content">
          <van-steps direction="vertical" :active="0" active-color="#2589FF">
            <van-step v-for="(item, index) in timelineDtoList" :key="index">
              <template #active-icon>
                <span class="active-type">{{ traceTypeObj[item.traceType] }}</span>
              </template>
              <template #inactive-icon>
                <span class="inactive-type">{{ traceTypeObj[item.traceType] }}</span>
              </template>
              <h3>
                <span v-if="item.traceType == 1">备注：{{ item.content }}</span>
                <span v-if="item.traceType == 2">跟进备注：{{ item.content }}</span>
                <span v-if="item.traceType == 3">新建线索</span>
                <span v-if="item.traceType == 4">回访记录：{{ item.content }}</span>
                <div class="change-user" v-if="item.traceType == 5">
                  <span>转交人：{{ item.beforeConsultName }} —> {{ item.afterConsultName }}</span>
                  <p>转交备注：{{ item.content }}</p>
                </div>
                <span v-if="item.traceType == 6">
                  客户状态：{{ clueStatusObj[item.beforeClueStatus] }} —> {{ clueStatusObj[item.afterClueStatus] }}
                </span>
              </h3>
              <p>{{ changeTime(item) }}</p>
            </van-step>
          </van-steps>
        </div>
      </section>
      <section class="text-item">
        <h1 class="van-hairline--bottom"><i class="text-icon"></i>线索备案记录</h1>
        <div class="text-content">
          <li class="log-li van-hairline--bottom" v-for="item in clueRecordDtoList">
            <p class="info-item">
              <span class="info-title">来访渠道</span>
              <span>{{ item.channelTypeName }}</span>
            </p>
            <p class="info-item">
              <span class="info-title">招商经理</span>
              <span>{{ item.fullName }}</span>
            </p>
            <p class="info-item">
              <span class="info-title">合伙人</span>
              <span>{{ item.partnerName }}</span>
            </p>
            <p class="info-item">
              <span class="info-title">备案时间</span>
              <span>{{ formatDate(item.recordTime, 5) }}</span>
            </p>
          </li>
        </div>
      </section>
    </van-popup>
  </div>
</template>

<script>
import { getNewClueInfo } from "@/api/clue/clue";
import { formatDate } from "@/utils/utils";
import { clueStatusObj, clueStatusColorObj } from "@/db/clue";
export default {
  name: "ClueInfo",
  props: ['clueId'],
  data() {
    return {
      clueStatusObj,
      clueStatusColorObj,
      formatDate,
      showFlag: false,
      clueInfoDto: "",
      clueRecordDtoList: [],
      clueRemarkDtoList: [],
      timelineDtoList: [],
      traceTypeObj: {
        1: "备",
        2: "跟",
        3: "建",
        4: "访",
        5: "转",
        6: "变",
      },
    }
  },
  methods: {
    // 打开页面
    init() {
      this.showFlag = true;
      this.getClueInfo();
    },

    changeTime(item) {
      let str = `
        ${formatDate(item.recordTime, 5)}
        ${item.recordUserName} 
      `
      return str
    },

    // 【请求】线索详情
    getClueInfo() {
      let data = {
        clueId: this.clueId
      }
      getNewClueInfo(data).then((res) => {
        if (res.success) {
          this.clueInfoDto = res.data.clueInfoDto;
          this.clueRecordDtoList = res.data.clueRecordDtoList;
          this.clueRemarkDtoList = res.data.clueRemarkDtoList;
          this.timelineDtoList = res.data.timelineDtoList;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.clue-info {
  display: flex;
}

.popup {
  width: 100%;
  height: 100%;
  background: #f1f2f6;
}

.van-nav-bar {
  background: #1989fa;
}

.text-item {
  background: #fff;
  border-bottom: .15rem #f1f2f6 solid;

  h1 {
    padding: .1rem;
    display: flex;
    align-items: center;
  }

  .text-icon {
    display: inline-block;
    background: #1989fa;
    width: 0.04rem;
    border-radius: 0 0.04rem 0.04rem 0;
    margin-right: 0.1rem;
    height: 0.16rem;
  }

  .text-content {
    padding: .1rem;
  }
}

.text-item:last-child {
  border-bottom: none;
}

.info-item {
  display: flex;
  padding: .05rem 0;
}

.info-title {
  color: #666;
  min-width: .8rem;
}

.log-li {
  padding-bottom: .15rem;
  margin-bottom: .15rem;
}

.active-type {
  display: inline-flex;
  justify-content: center;
  align-content: center;
  width: .2rem;
  height: .2rem;
  line-height: .2rem;
  border-radius: 50%;
  border: 1px solid #2589FF;
  color: #2589FF;
}

.inactive-type {
  display: inline-flex;
  justify-content: center;
  align-content: center;
  width: .2rem;
  height: .2rem;
  line-height: .2rem;
  border-radius: 50%;
  border: 1px solid #969799;
  color: #969799;
}

.change-user {
  display: inline-flex;
  flex-direction: column;
}
</style>

<style lang="scss">
.clue-info {

  .van-nav-bar .van-icon,
  .van-nav-bar__text,
  .van-nav-bar__title {
    color: #fff !important;
    font-size: .18rem;
  }
}
</style>