<template>
  <div id="AccessoryFile">
    <h1 class="title van-hairline--bottom">{{ title }}</h1>
    <div class="middle" v-if="fileList.length > 0">
      <div class="comment-files">
        <div class="comment-img">
          <van-image class="comment-img-item" v-for="(it, index) in changeFileList(fileList).imgList" :key="it.fileId"
            :src="it.fileUrl" @click="changeImgs(changeFileList(fileList).imgList, index)" />
        </div>
        <div class="comment-a">
          <a class="comment-a-item" v-for="i in changeFileList(fileList).fileList" :key="i.fileId" :href="i.fileUrl"
            :download="i.fileName">
            {{ i.fileName || '文件下载' }}
          </a>
        </div>
      </div>
    </div>
    <div class="middle" v-if="workflowAppList.length > 0">
      <span>审定单：</span>
      <van-image class="comment-img-item" v-for="(i, index) in workflowAppList" :key="index" :src="i.fileUrl"
        @click="changeImgs(workflowAppList, index)" />
    </div>
    <div class="middle" v-if="workflowCheckList.length > 0">
      <span>验收单：</span>
      <van-image class="comment-img-item" v-for="(i, index) in workflowCheckList" :key="index" :src="i.fileUrl"
        @click="changeImgs(workflowCheckList, index)" />
    </div>
    <div class="middle" v-if="workflowDelayList.length > 0">
      <span>延期附件：</span>
      <van-image class="comment-img-item" v-for="(i, index) in workflowDelayList" :key="index" :src="i.fileUrl"
        @click="changeImgs(workflowDelayList, index)" />
    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
export default {
  name: "AccessoryFile",
  props: ["approveFileList", "title"],
  /* 计算属性，值是对象，对象中放的都是方法，且必须有返回值 */
  computed: {
    // 附件
    fileList() {
      return this.approveFileList.filter(i => i.fileType == 1)
    },
    // 审定单
    workflowAppList() {
      return this.approveFileList.filter(i => i.fileType == 2)
    },
    // 验收单
    workflowCheckList() {
      return this.approveFileList.filter(i => i.fileType == 3)
    },
    // 延期附件
    workflowDelayList() {
      return this.approveFileList.filter(i => i.fileType == 4)
    },
  },
  methods: {
    // 【转换】文件信息
    changeFileList(list) {
      let obj = {
        fileList: [],
        imgList: []
      }
      if (list) {
        for (let i of list) {
          if (this.readFileType(i.fileUrl)) {
            obj.imgList.push(i)
          } else {
            obj.fileList.push(i)
          }
        }
      }
      return obj
    },

    // 【识别】文件类型
    readFileType(url) {
      url = url.split('.')
      let type = url[url.length - 1]
      if (type == "jpg" || type == "gif" || type == "png" || type == "bmp") {
        return true
      } else {
        return false
      }
    },

    // 【监听】图片预览
    changeImgs(images, index) {
      let arr = []
      for (let i of images) {
        arr = [...arr, i.fileUrl]
      }
      ImagePreview({
        images: arr,
        startPosition: index,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#AccessoryFile {
  margin-bottom: 0.15rem;
  background: #fff;
  position: relative;

  .title {
    padding: 0.1rem;
    font-size: 0.16rem;
  }

  .middle {
    padding: 0.2rem;
    display: flex;
    align-items: center;
  }
}

/* 评论附件预览 */
.comment-files {
  // background-color: #F0F2F5;
  margin-top: .1rem;
  padding: .1rem;
}

.comment-img {
  display: flex;
  flex-wrap: wrap;
}

.comment-img-item {
  width: .48rem;
  height: .48rem;
  margin-right: .1rem;
  margin-bottom: .1rem;
}

.comment-img-item:last-child {
  margin-right: 0;
}


.comment-a-item {
  display: flex;
  color: #1E9FFF;
  margin-bottom: 10px;
}

.comment-a-item:last-child {
  margin-bottom: 0;
}
</style>