<template>
  <div class="page">
    <h5 class="title">施工合同签署</h5>
    <van-cell-group inset>
      <van-cell title="合同名称" :value="params.workContractName" />
      <van-cell title="合同价款" :value="params.contractAmount" />
      <van-cell title="签署单位" :value="params.signCompanyName" />
      <van-cell title="签署状态" :value="params.contractStatusName" />
    </van-cell-group>
    <div style="margin: 16px;">
      <van-button block type="info" size="small" @click="onCopy" v-if="params.linkUrl">复制签署链接</van-button>
      <van-button block type="info" size="small" @click="onSubmit" v-else>生成并发起签署</van-button>
    </div>
  </div>
</template>

<script>
import { getDealActionVoFind, getDealAction } from "@/api/task/task";
import { Toast } from "vant";
export default {
  props: ['taskList', 'approveId', 'assigneeEventAction', 'processType', 'executionId'],
  data() {
    return {
      params: {
        workContractName: "",
        contractAmount: "",
        signCompanyName: "",
        contractStatusName: "",
        linkUrl: "",
      }
    }
  },
  mounted() {
    this.getDealActionVoFind()
  },
  methods: {
    // 【请求】获取客户数量
    getDealActionVoFind() {
      let data = {
        processType: this.processType,
        executionId: this.executionId,
        approveId: this.approveId,
        eventAction: this.assigneeEventAction,
      };
      getDealActionVoFind(data).then((res) => {
        if (res.success && res.data) {
          this.params.workContractName = res.data.workContractName;
          this.params.contractAmount = res.data.contractAmount;
          this.params.signCompanyName = res.data.signCompanyName;
          this.params.contractStatusName = res.data.contractStatusName;
          this.params.linkUrl = res.data.linkUrl;
        } else {
          Toast.fail(res.msg);
        }
      });
    },

    // 【监听】生成并发起签署
    onSubmit() {
      let data = {
        processType: this.processType,
        executionId: this.executionId,
        approveId: this.approveId,
        eventAction: this.assigneeEventAction,
      };
      getDealAction(data).then((res) => {
        if (res.success && res.data) {
          Toast.success(res.msg);
          this.getDealActionVoFind();
        } else {
          Toast.fail(res.msg);
        }
      });
    },

    // 【监听】复制签署链接
    onCopy(){
      let text = this.params.linkUrl;
      var input = document.createElement("textarea");
      input.value = text;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      Toast.success("已复制到剪贴板！");
    }
  }
};
</script>
<style lang="scss" scoped>
.title {
  padding: .15rem;
  font-size: .16rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>