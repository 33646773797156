<template>
  <div class="page">
    <h5 class="title">指定施工办理人</h5>
    <!-- 弹窗 -->
    <van-form @submit="onSubmit">
      <van-field v-model="params.fullName" name="empId" label="指定施工办理人" placeholder="请选择指定施工办理人" :rules="rules.empId"
        required readonly clickable @click="showPickerEmpId = true" label-width="1rem" />
      <div style="margin: 16px;">
        <van-button block type="info" size="small" native-type="submit">确 认</van-button>
      </div>
    </van-form>
    <!-- 指定施工办理人弹窗 -->
    <van-popup v-model="showPickerEmpId" round position="bottom" get-container="#app">
      <van-picker show-toolbar :columns="handleEmpUsers" value-key="fullName" @cancel="showPickerEmpId = false"
        @confirm="onConfirmEmpId" :default-index="3" />
    </van-popup>
  </div>
</template>

<script>
import { rules } from "@/utils/rules";
import { Toast } from "vant";
import { getHandleEmpUser, getDealAction, getDealActionVo } from "@/api/task/task"
export default {
  name: "Reference",
  props: ['taskList', 'approveId', 'assigneeEventAction', 'processType', 'executionId'],
  data() {
    return {
      rules,
      params: {
        fullName: "",
        empId: '',
      },
      showPickerEmpId: false,
      handleEmpUsers: [],//指定设计师列表
    }
  },
  mounted() {
    this.getDealActionVo();
    this.getHandleEmpUser();
  },
  methods: {
    // 【请求】办理回显
    getDealActionVo() {
      let data = {
        processType: this.processType,
        executionId: this.executionId,
      };
      getDealActionVo(data).then(res => {
        if (res.success && res.data) {
          this.params = res.data;
        }
      })
    },

    // 【请求】施工办理人列表
    getHandleEmpUser() {
      let data = {
        workSheetRoleType: 3,
        approveId: this.approveId
      };
      getHandleEmpUser(data).then(res => {
        if (res.success && res.data) {
          this.handleEmpUsers = res.data;
        }
      })
    },

    // 【监听】点击设计师
    onConfirmEmpId(value) {
      console.log('value: ', value);
      this.params.fullName = value.fullName;
      this.params.empId = value.empId;
      this.showPickerEmpId = false;
    },

    onSubmit() {
      let data = this.params;
      data.approveId = this.approveId;
      data.assigneeEventAction = this.assigneeEventAction;
      data.processType = this.processType;
      data.executionId = this.executionId;
      getDealAction(data).then(res => {
        if (res.success && res.data) {
          Toast(res.msg);
          this.$emit('reTransact')
        } else {
          Toast(res.msg);
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.title {
  padding: .15rem;
  font-size: .16rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tips {
  font-size: .12rem;
  color: #999;
  padding: .15rem;
}
</style>

