<template>
  <div id="FormWorkInfo">
    关联工单
    <van-button plain type="info" class="btn-blue" @click="show = true">查看</van-button>
    <van-popup v-model="show" position="right" :style="{ height: '100%', width: '80%' }">
      <p class="title">{{ title }}</p>
      <van-cell title="工单类型" :value="workFormTypeObj[formWorkInfo.workFormType]" />
      <van-cell title="工单名称" :value="formWorkInfo.formName" />
      <van-cell title="发单时间" :value="formWorkInfo.applyTime" />
      <van-cell title="截止时间" :value="formWorkInfo.requestFinishTime" />
      <van-cell title="工单级别" :value="formLevelObj[formWorkInfo.formLevel]" />
      <van-cell title="所属园区" :value="formWorkInfo.projectName" />
      <template v-if="formWorkInfo.workFormType != '2'">
        <van-cell title="维修地址" :value="formWorkInfo.location" />
        <van-cell title="联系人员" :value="formWorkInfo.contactName" />
        <van-cell title="联系电话" :value="formWorkInfo.contactPhone" />
      </template>
      <van-cell title="预计费用" :value="formWorkInfo.budgetAmount" />
      <template v-if="formWorkInfo.workFormType != '1'">
        <van-cell title="工单种类" :value="formWorkInfo.formTypeName" />
      </template>
      <template v-if="formWorkInfo.workFormType == '3'">
        <van-cell title="信息来源" :value="informationChannelObj[formWorkInfo.informationChannel]" />
        <van-cell title="消防改动" :value="formWorkInfo.relateFire ? '有改动' : '未改动'" />
      </template>
      <van-cell title="情况描述" :value="formWorkInfo.content" />
      <van-cell title="备注信息" :value="formWorkInfo.remark" />
      <div class="comment-files">
        <div class="comment-img">
          <van-image class="comment-img-item" v-for="(it, index) in changeFileList(formWorkInfo.fileList).imgList"
            :key="it.fileId" :src="it.fileUrl"
            @click="onImagePreview(changeFileList(formWorkInfo.fileList).imgList, index)" />
        </div>
        <div class="comment-a">
          <a class="comment-a-item" v-for="i in changeFileList(formWorkInfo.fileList).fileList" :key="i.fileId"
            :href="i.fileUrl" :download="i.fileName">
            {{ i.fileName || '文件下载' }}
          </a>
        </div>
      </div>

    </van-popup>
  </div>
</template>
<script>
import { ImagePreview } from "vant";
export default {
  name: "FormWorkInfo",
  props: ["formWorkInfo", "title"],
  data() {
    return {
      show: false,
      workFormTypeObj: {
        1: "内部工单",
        2: "工程预算联系单",
        3: "工程改造申请",
      },
      formLevelObj: {
        1: "特急",
        2: "紧急",
        3: "普通",
      },
      formTypelObj: {
        1: "物业维修",
        2: "工程改造",
        3: "空置房改造",
      },
      informationChannelObj: {
        1: "来电",
        2: "来访",
        3: "常规巡查",
        4: "其他",
      }
    }
  },
  methods: {
    // 【转换】文件信息
    changeFileList(list) {
      let obj = {
        fileList: [],
        imgList: []
      }
      if (list) {
        for (let i of list) {
          if (this.readFileType(i.fileUrl)) {
            obj.imgList.push(i)
          } else {
            obj.fileList.push(i)
          }
        }
      }
      return obj
    },


    // 【识别】文件类型
    readFileType(url) {
      url = url.split('.')
      let type = url[url.length - 1]
      if (type == "jpg" || type == "gif" || type == "png" || type == "bmp") {
        return true
      } else {
        return false
      }
    },


    // 【监听】图片预览
    onImagePreview(images, index) {
      console.log('images: ', images);
      let arr = []
      for (let i of images) {
        arr = [...arr, i.fileUrl]
      }
      ImagePreview({
        images: arr,
        startPosition: index,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#FormWorkInfo {
  margin-bottom: 0.15rem;
  padding: .15rem;
  background: #fff;
  display: flex;
  align-items: center;
}

.btn-blue {
  margin-left: .2rem;
  background: rgba(37, 137, 255, 0.1);
  width: .8rem;
  height: .3rem;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #4f94f3;
  color: #fff;
  height: .4rem;
}

.files {
  padding: .2rem;
}

/* 评论附件预览 */
.comment-files {
  // background-color: #F0F2F5;
  margin-top: .1rem;
  padding: .1rem;
}

.comment-img {
  display: flex;
  flex-wrap: wrap;
}

.comment-img-item {
  width: .48rem;
  height: .48rem;
  margin-right: .1rem;
  margin-bottom: .1rem;
}

.comment-img-item:last-child {
  margin-right: 0;
}


.comment-a-item {
  display: flex;
  color: #1E9FFF;
  margin-bottom: 10px;
}

.comment-a-item:last-child {
  margin-bottom: 0;
}
</style>